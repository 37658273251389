import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  className?: string
  image?: ImageProps
  languageCode: string
  title: string
  URL: string
}

export const Item = memo(function Item({
  className,
  image,
  languageCode,
  title,
  URL,
}: Props) {
  if (!title || !URL) {
    return null
  }

  return (
    <Container className={className} to={URL}>
      {image ? (
        <LazyLoadComponent>
          <Image {...image} />
        </LazyLoadComponent>
      ) : null}

      <Wrapper>
        <Title>{title}</Title>
        <CTA className="slide-cta">
          {useVocabularyData('read', languageCode)}
        </CTA>
      </Wrapper>
    </Container>
  )
})

const Container = styled(Link)`
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  overflow: hidden;
  padding-bottom: 38.8%;
  position: relative;
  text-align: left;
  &:hover {
    &:before {
      opacity: 0.4;
    }
    img {
      transform: scale(1.1);
    }
    .slide-cta {
      &:after {
        right: 0;
      }
    }
  }
  &:before,
  &:after {
    content: '';
    width: 100%;
    backface-visibility: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    z-index: 1;
  }
  &:before {
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.3;
  }
  &:after {
    height: 50%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 0.5)}
    );
  }

  img {
    width: 101%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out !important;
  }

  @media (max-width: 1023px) {
    padding-bottom: 50%;
  }

  @media (max-width: 767px) {
    padding-bottom: 111.5%;
  }
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 2.5rem;
  right: 2.5rem;
  left: 2.5rem;
  z-index: 2;

  @media (max-width: 1023px) {
    bottom: 2.125rem;
    right: 2rem;
    left: 2rem;
  }
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`

const CTA = styled.span`
  display: inline-block;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.075rem;
  line-height: 1.75rem;
  margin-top: 1.3125rem;
  position: relative;
  text-transform: uppercase;
  &:after {
    content: '';
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    position: absolute;
    bottom: -0.25rem;
    right: 100%;
    left: 0;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1023px) {
    &:after {
      right: 0;
    }
  }
`
